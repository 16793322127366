.results {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

table {
    max-width: 500px;
    border-collapse: collapse;
    margin: 5px 5px 15px 5px;
    background-color: white;
    border-bottom: 2px solid black;
    border-top: 2px solid black;
}

th, td {
    border-top: 1px solid black;
    border-left: 3px double black;
    border-right: 3px double black;
}

tr.no-borders th, tr.no-borders td {
    border: none;
}

tr.no-borders td {
    border-bottom: 2px solid black;
    border-left: 2px solid black;
    border-right: 2px solid black;
    text-align: auto;
}

tbody.second-table {
    border: 2px solid black;

}

tr.no-borders td.light-underline {
    border-bottom: 1px solid gray;
}

tr.no-borders td.heavy-top {
    border-top: 2px solid black;
}

.heading {
    border-top: 3px solid black;
    text-decoration: underline;
    border-bottom: 1px solid black;
}

.row-head {
    text-align: right;
}

#fuel-cell {
    display: flex;
}

#max-fuel-button {
    width: 35px;
    height: 3em;
    text-align: center;
    padding: 0;
}

#max-fuel-button-external {
    display: inline-block;
    width: 70px;
}

#fuel-head {
    display: inline-flex;
    flex-direction: column;
}

.ext-ops-fuel {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.fuel-radio {
    display: block;
    min-width: 65px;
}

label.fuel-radio span {
    margin-right: 5px;
}

#fuel-container {
    display: inline-block;
    text-align: left;
}

#fuel-input {
    width: 30px;
    margin: 0;
    padding: 0;
}

input.pax, input.baggage, input.paxExternal, input.extLoad {
    width: 50px;
}

input.extFuelGal {
    width: 40px;
}

.good {
    background-color: lightgreen;
    color: green;
    font-weight:bold;
}

td.good span, td.caution span, td.goodcaution span {
    display:none;
}

.err, .gooderr {
    background-color: pink;
    color: red;
    font-weight: bold;
}

.caution, .goodcaution {
    background-color: lightyellow;
    color: goldenrod;
    font-weight: bold;
}

.gray {
    background-color: lightgray;
    border: 0px solid lightgray;
}

#torque-i {
    display: inline;
}

#torque-info {
    visibility: hidden;
    width: 200px;
    position: fixed;
    z-index: 1;
    background-color: black;
    color: white;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    font-size: 12px;
    font-weight: 400;
    bottom: 100px;
    right: 100px;
}

@media(hover: hover) and (pointer: fine) {
    #torque-i:hover #torque-info {
        visibility: visible;
    }
}

@media(any-pointer: coarse) {
    #torque-i:hover #torque-info {
        visibility: visible;
    }
}