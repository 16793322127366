div.AC-view {
    text-align: center;
}

div.AC-view table {
    display:inline-flex;
    justify-content:center;
}

div.AC-view tbody {
    border: 3px double black;
}