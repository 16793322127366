div.change-display button {
    background-color: #282c34;
    width: 150px;
    max-width: 18%;
    color: #dadada;
}

div.change-display button.selected {
    background-color: skyblue;
    border-bottom: 2px solid skyblue;
    color: black;
    font-weight: bold;
}
