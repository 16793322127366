.form {
    display: flex;
    justify-content: center;
}

.header {
    text-align: end;
}

#side, #studentWeight, #mxTmp, #pa, #da, #eventName {
    width: 44px;
}

#instructorWeight, #spot {
    width: 38px;
}

#date {
    width: 60px;
}

#studentName {
    width: 80px;
}

input {
    margin: 0 5px;
}

@media only screen and (max-width: 600px) {
    .shorten {
        display: none;
    }
}