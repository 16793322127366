.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.AC-view, .INST-view, .WB-view, .ABOUT-view, .ORM-view {
  width: 100%;
  max-width: 650px;
  border-radius: 10px;
  background-color: skyblue;
}

@media only screen and (max-width: 650px) {
  .AC-view, .INST-view, .WB-view, .ABOUT-view {
    width: 100%;
    max-width: 650px;
    border-radius: 5px;
  }
}

.logo-holder {
  margin: -10px 0px -10px -30px;
  padding: 0;
}

.helo {
  width: 50px;
  display: inline-block;
  position: relative;
  z-index: 1;
  left: 50px;
  transform: rotateZ(-45deg);
  -ms-transform: rotateZ(-45deg);
  -webkit-transform: rotateZ(-45deg);
  -moz-transform: rotateZ(-45deg);
  -o-transform: rotateZ(-45deg);
}

.rotor {
  width: 50px;
  height: 50px;
  object-fit: cover;
  object-position: 0 0;
  scale: calc(100/100);
  display: inline-block;
  position: relative;
  z-index: 2;
}

.App-logo {
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 5s linear;
  }
}

.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  cursor: pointer;
  width: 100%
}

.App-header span {
  display: inline-block;
  position: relative;
}

.App-link {
  color: #61dafb;
}

.App-button {
  margin: 10px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}

footer p {
  font-size: x-small;
}

.selected {
  border-style: inset;
  font-weight: bold;
}

.url {
  display: none;
}

button {
  display: inline-block;
  border-radius: 0;
  margin: 0 2px;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: #dadada;
  border: 2px outset #dadada;
}